<template>
    <b-modal v-model="show" :title="modalTitle" :hide-footer="true">
        <p class="warning" v-if="!hasReplication"> ⚠️ Cette campagne ne possède pas de données de replication </p>
        <p>URL des bannières des campagnes enfants :</p>
        <b-overlay :show="fetchingCampaignChildren">
            <p v-if="isCampaignStartDateToday" class="warning">⚠️ Vous êtes sur le point de lancer une campagne pour aujourd'hui</p>

            <b-form-group v-for="camp in validChildrenCampaign" :key="camp.campaign_code">
                <label class="campChildTitle">Campagne {{ camp.media_support_code }}</label>
                <div class="modifyCamp">
                    <label :for="'crea_' + camp.media_support_code"> Thème</label>
                    <b-form-select v-if="validMedia.includes(camp.media_support_code)"
                        id="'crea_'+camp.media_support_code" v-model="creaSelected[camp.campaign_code]"
                        :options="formatCreaOptions" class="mb-3" value-field="item" text-field="name"
                        disabled-field="notEnabled"></b-form-select>
                </div>

                <div class="modifyCamp">
                    <label :for="camp.media_support_code"> Url de la bannière </label>
                    <b-form-input v-if="validMedia.includes(camp.media_support_code)"
                        v-model="creativesUrl[camp.campaign_code]" :id="camp.campaign_code" type="url"
                        :state="creativeUrlState[camp.campaign_code]"
                        aria-describedby="input-live-help input-live-feedback" autofocus required
                        placeholder="https://adcleek.com/" />
                    <p v-else>URL non necessaire</p>
                    <b-form-invalid-feedback id="input-live-feedback"> URL incorrecte </b-form-invalid-feedback>
                </div>

                <b-btn style="margin-top: 20px" id="btnCreateCampaignProvider"
                    v-if="camp.details.creaData && camp.details.creaData.code"
                    :disabled="disableCreateStatus[camp.campaign_code]" @click="modifyCampaign(camp.campaign_code)"
                    variant="primary">
                    Modifier
                </b-btn>
                <b-btn style="margin-top: 20px" id="btnCreateCampaignProvider"
                    v-if="camp.details && !camp.details.creaData" :disabled="disableCreateStatus[camp.campaign_code]"
                    @click="createProviderCampaign(camp.campaign_code)" variant="primary">
                    Créer
                </b-btn>
            </b-form-group>

            <br />
            <b-btn id="btnCreateCampaignProvider" v-if="showGlobalCreateStatus" :disabled="disableGlobalCreateStatus"
                @click="createProviderCampaign()" variant="primary">Créer</b-btn>
        </b-overlay>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            btnCreateCampaignProviderState: false,
            creativesUrl: {},
            hasReplication: true,
            creativeUrlStatus: true,
            childrenCampaign: [],
            show: false,
            fetchingCampaignChildren: true,
            campMedias: [],
            validMedia: process.env.VUE_APP_TTD_VALID_MEDIA ? process.env.VUE_APP_TTD_VALID_MEDIA.split(',') : '',
            validMediaNoUrl: process.env.VUE_APP_TTD_VALID_MEDIA_NO_URL ? process.env.VUE_APP_TTD_VALID_MEDIA_NO_URL.split(',') : '',
            creas: [],
            creaOptions: [],
            creaSelected: {},
            parentCampaign: {},
            showCreateStatus: {},
            showGlobalCreateStatus: false,
            validCamp: {},
        };
    },
    computed: {
        ...mapGetters({
            successToast: 'app/successToast',
            errorToast: 'app/errorToast',
            // getCreaByBrand: 'app/getCreaByBrand'
        }),

        modalTitle() {
            const firstValidChildCampaign = this.validChildrenCampaign[0];
            const details = firstValidChildCampaign && firstValidChildCampaign.details;
            const creaData = details && details.creaData;
            const code = creaData && creaData.code;

            // A modifier quand nouvelle action de changer media provider
            return code ? 'Modifier le média chez le provider' : 'Créer le média chez le provider';
        },
        creativeUrlState() {
            let creativeUrlState = {};

            let status = Object.keys(this.creativesUrl).length > 0 ? true : false;

                // A modifier quand nouvelle action de changer media provider
                return code ? 'Modifier le média chez le provider' : 'Créer le média chez le provider';
            },

            isCampaignStartDateToday() {
                const today = new Date().toISOString().slice(0, 10); 
                return this.validChildrenCampaign.some(camp => camp.start_date === today);
            },

            creativeUrlState() {
                let creativeUrlState = {};

            this.creativeUrlStatus = !status;
            return creativeUrlState;
        },

        disableCreateStatus() {
            let disableCreateStatus = {};
            this.childrenCampaign.forEach((childCamp) => {
                const creaInBase =
                    childCamp.details.creaData && childCamp.details.creaData.code
                        ? childCamp.details.creaData.code
                        : this.parentCampaign?.details?.crea_code
                            ? this.parentCampaign.details.crea_code
                            : '';
                const creaUrlInBase = childCamp.details.creaData && childCamp.details.creaData.url ? childCamp.details.creaData.url : '';

                if (this.creaSelected[childCamp.campaign_code] != creaInBase || (this.creativesUrl[childCamp.campaign_code] != creaUrlInBase && this.creativeUrlState[childCamp.campaign_code]))
                    disableCreateStatus[childCamp.campaign_code] = false;
                else if (this.validMediaNoUrl.includes(childCamp.media_support_code)) disableCreateStatus[childCamp.campaign_code] = false;
                else disableCreateStatus[childCamp.campaign_code] = true;
            });

            return disableCreateStatus;
        },

        validChildrenCampaign: function () {
            const nbCampNoUrl = this.childrenCampaign.filter((cc) => this.validMediaNoUrl.includes(cc.media_support_code));

            const validChildrenCamps = this.childrenCampaign.filter((cc) => this.validMedia.includes(cc.media_support_code) || this.validMediaNoUrl.includes(cc.media_support_code));

            return validChildrenCamps;
        },

        formatCreaOptions() {
            let creaOptions = [];
            if (this.creas.length) {
                this.creas.forEach((c) => {
                    creaOptions.push({ item: c.crea_code, name: c.label });
                });
            }
            // this.creaOptions = creaOptions
            return creaOptions;
        },

        disableGlobalCreateStatus() {
            let globalStatus = false;
            if (Object.keys(this.disableCreateStatus).length) {
                const codes = Object.keys(this.disableCreateStatus);
                globalStatus = this.disableCreateStatus[codes[0]];
                for (const code in this.disableCreateStatus) {
                    globalStatus = globalStatus || this.disableCreateStatus[code];
                }
            }
            return globalStatus;
        },
    },
    methods: {
        urlCheck(url) {
            const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            if (url.match(regex)) {
                return true;
            } else {
                return false;
            }
        },
        close() {
            this.creativesUrl = {};
            this.show = false;
            this.fetchingCampaignChildren = true;
        },
        async open(campaignCode, childCampaignCode) {
            this.fetchingCampaignChildren = true;
            this.show = true;

            this.$store
                .dispatch('app/genericGet', this.$store.state.app.apiBillingiUrl.concat('/children-campaign/?code=' + campaignCode))

                .then(async (res) => {
                    for (const media of res.data) {
                        if (media.campaign_code === childCampaignCode) {
                            res.data = [media];
                        }
                    }
                    this.$set(this, 'childrenCampaign', res.data);

                    const creas = await this.$store.dispatch('app/getCreaByBrand', res.data[0].brand);

                    this.$set(this, 'creas', creas.data.data);

                    const parentCampRaw = await this.$store.dispatch('app/getCampaign', { campaignCode: campaignCode });
                    this.$set(this, 'parentCampaign', parentCampRaw.data);


                    const replications = await this.$store.dispatch('app/getPointCodeReplication', { point_code: res.data[0].point_code, brand: res.data[0].brand })

                    if (replications.data.length === 0) {
                        this.hasReplication = false                    }

                    if (this.childrenCampaign.length && Object.keys(this.parentCampaign).length) {
                        this.childrenCampaign.forEach((childCamp) => {
                            const initCreaCode =
                                childCamp.details.creaData && childCamp.details.creaData.code
                                    ? childCamp.details.creaData.code
                                    : this.parentCampaign.details.crea_code
                                        ? this.parentCampaign.details.crea_code
                                        : '';
                            this.$set(this.creaSelected, childCamp.campaign_code, initCreaCode);

                            const initCreaUrl = childCamp.details.creaData && childCamp.details.creaData.url ? childCamp.details.creaData.url : '';
                            this.$set(this.creativesUrl, childCamp.campaign_code, initCreaUrl);

                            if (childCamp.status == 0) this.showCreateStatus[childCamp.campaign_code] = true;
                            else this.showCreateStatus[childCamp.campaign_code] = false;

                            if (this.validMedia.includes(childCamp.media_support_code)) this.validCamp[childCamp.campaign_code] = true;
                            else this.validCamp[childCamp.campaign_code] = false;
                        });
                    }
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    this.fetchingCampaignChildren = false;
                });
        },
        async createProviderCampaign(campCode = null) {
            this.$root.toggleGlobalLoading(true);

            //create campaign in provider
            try {
                const selectedCampaigns = campCode ? this.validChildrenCampaign.filter((vcc) => vcc.campaign_code == campCode) : this.validChildrenCampaign;
                for (const campaign of selectedCampaigns) {
                    const code = campaign.campaign_code;
                    const data = {
                        creaCode: this.creaSelected[campaign.campaign_code] ? this.creaSelected[campaign.campaign_code] : '',
                        creativeUrl: this.creativesUrl[campaign.campaign_code] ? this.creativesUrl[campaign.campaign_code] : '',
                    };

                    await this.$store.dispatch('app/createProviderCampaign', { code, data });
                }

                this.close();

                this.$toast({
                    component: 'toast',
                    props: {
                        title: 'Campagnes crées avec succès',
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                    },
                });
            } catch (e) {
                console.error(e);
                this.$toast({
                    component: 'toast',
                    props: {
                        title: 'Erreur lors de la création des campagnes',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                });
            } finally {
                this.$root.toggleGlobalLoading(false);
            }
        },
        async modifyCampaign(campCode) {
            this.$root.toggleGlobalLoading(true);

            try {
                const data = {
                    creaCode: this.creaSelected[campCode] ? this.creaSelected[campCode] : '',
                    creativeUrl: this.creativesUrl[campCode] ? this.creativesUrl[campCode] : '',
                };

                await this.$store.dispatch('app/updateProviderCampaign', { code: campCode, data: data });

                this.$toast({
                    component: 'toast',
                    props: {
                        title: 'Provider média modifié avec succès',
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                    },
                });
            } catch (e) {
                console.error(e);
                this.$toast({
                    component: 'toast',
                    props: {
                        title: 'Erreur lors de la modification du provider média',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                });
            } finally {
                this.$root.toggleGlobalLoading(false);
            }
        },
    },
};
</script>

<style>
    .modifyCamp select {
        margin-bottom: 5px !important;
    }
    .campChildTitle {
        font-weight: 600;
    }

    .warning{
        color: red !important;
    }
</style>

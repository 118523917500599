<!-- eslint-disable vue/no-unused-components -->
<template>
    <b-modal v-model="show" :title="'Ajout d\'un thème a une campagne en cours'" :hide-footer="true">
        <b-overlay :show="fetchingCampaignChildren">
            <div class="flex">
                <b-row>
                    <!-- basic select -->
                    <b-col md="6">
                        <b-form-group>
                            <v-select id="selector" v-model="selected" class="select-size-lg" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="label" :options="creas" :closeOnSelect="false">
                            </v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-button variant="primary" class="btn-icon" @click="download(selected)">
                    <feather-icon icon="DownloadIcon" size="16" />
                </b-button>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
    import RangeDate from './range-date.vue';
    import { mapGetters } from 'vuex';
    import { BRow, BCol, BFormGroup } from 'bootstrap-vue';

    import vSelect from 'vue-select';

    import state from 'rtlcss/lib/state';
    import axios from 'axios';
    export default {
        components: {
            // eslint-disable-next-line vue/no-unused-components
            RangeDate,
            BRow,
            BCol,
            BFormGroup,
            vSelect,
        },
        data() {
            return {
                campaign: {},
                childrenCampaign: [],
                show: false,
                fetchingCampaignChildren: true,
                currentDate: '',
                creas: [],
                selected: '',
                bannerCode: '',
                pointCode: '',
            };
        },
        computed: {
            ...mapGetters({
                formatToDate: 'app/formatToDate',
            }),
        },
        methods: {
            close() {
                this.code = '';
                this.currentDate = '';
                this.show = false;
                this.fetchingCampaignChildren = true;
            },
            open(startDate, endDateRaw, code, pointCode) {
                this.code = code;
                this.fetchingCampaignChildren = true;
                this.show = true;
                this.$store
                    .dispatch('app/genericGet', this.$store.state.app.apiBillingiUrl.concat('/children-campaign/?code=' + code))
                    .then((res) => {
                        this.$set(this, 'childrenCampaign', res.data);
                        this.$set(this, 'bannerCode', code);
                        this.$set(this, 'pointCode', pointCode);
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally(() => {
                        this.fetchingCampaignChildren = false;
                    });
                this.fetchCreaData(code);
            },

            fetchCreaData(code) {
                let brand = code.split('_')[1].toLowerCase();
                let creas = this.$store.dispatch('app/getCreaByBrand', `${brand}`).then((res) => {
                    this.$set(this, 'creas', res.data.data);
                    this.$set(this, 'bannerCode', code);
                });
            },
            download(selected) {
                console.warn('bannerDownload A TESTER SUR CAMPAIGN AVEC STATUS 1 OU 0');
                this.$root.toggleGlobalLoading(true);

                var data = {
                    code: this.bannerCode,
                    pointCode: this.pointCode,
                    creaCode: selected.crea_code,
                };
                this.$store.dispatch('app/downloadBanner', data).then((res) => {
                    this.$root.toggleGlobalLoading(false);
                    if (res.data.length > 0) {
                        for (const url of res.data) {
                            const fileLink = document.createElement('a');
                            fileLink.href = url;
                            fileLink.target = '_blank';
                            fileLink.setAttribute('download', data.code);
                            document.body.appendChild(fileLink);
                            fileLink.click();
                        }

                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'La bannière a été téléchargée',
                                icon: 'smile',
                                variant: 'success',
                            },
                        });
                    } else {
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Pas de bannière à télécharger',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    }
                });

                this.selected = 0;
            },
        },
    };
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
    .flex {
        position: relative;
        width: 100%;
        display: flex;
        place-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    #selector {
        position: relative;
        min-width: 300px;
        flex-wrap: wrap;
    }

    #selector .custom-select {
        overflow: hidden;
    }
</style>
